import {Model} from "./model";

export class Crawl implements Model {
  id: number;
  audience_size: number;
  partition: string;
  created_at: Date;
  updated_at: Date;
  criteria_ready: boolean;
  segment_ready: boolean;

  constructor(audience_size: number, partition: string) {
    this.audience_size = audience_size;
    this.partition = partition;
  }
}
