import {StatItem} from '../../models/stats/stat-item';
import {BaseStatFilter} from '../filter/base-stat-filter';
import {Tag} from '../../models/tag';
import {Logger} from '../util/logger';

export enum DataType {
  SCORE, SELECTIVITY, NORMALIZED, RAW
}

export enum SourceType {
  SINGLE, LOWER, UPPER
}

export class StatHandler {
  private static readonly TYPE_WHITELIST = [
    "ages",
    "locales",
    "education_statuses",
    "family_statuses",
    "genders",
    "life_events",
    "publisher_platforms",
    "relationship_statuses"
  ];

  private itemsData: Map<string, StatItem[]> = new Map();
  /**
   * @Deprecated Slow performance
   * @private
   */
  private dynamicFilter: BaseStatFilter;
  private activeFilter: Map<string, boolean> = new Map();

  filtered: boolean = false;

  get(flag: string, withFilter: boolean = true, filterName ?: string, ids?: number[]): StatItem[] | null {
    flag = (this.activeFilter.get(flag) && withFilter) ? flag + "-filtered" : flag;

    // Choose between all or filtered data
    let items = this.dynamicFilter ?  this.dynamicFlagFilterBasic(flag) : this.itemsData.get(flag);
    if (items && filterName && filterName !== "") items = items.filter(i => i.name.toLowerCase().includes(filterName.toLowerCase()))

    if (!items) return null;
    if (ids) {
      items = items.filter(i => {
        return ids.includes(i.id)
      })
    }

    return items;
  }
  has(flag: string): boolean {
    return this.itemsData.has(flag);
  }
  keys(): string[] {
    return Array.from(this.itemsData.keys());
  }
  removeTag(tag: Tag) {
    const id = tag.id
    this.itemsData.set("tags", this.itemsData.get("tags").filter(t => t.id !== id));
    if (this.itemsData.has("tags-filtered")) {
      this.itemsData.set("tags-filtered", this.itemsData.get("tags-filtered").filter(t => t.id !== id));
    }
  }
  add(flag: string, items: StatItem[]) {
    this.itemsData.set(flag, items);
    this.activeFilter.set(flag, false);

    this.applyDefaultFilter(flag);
  }

  applyDefaultFilter(flag?: string) {
    // Init filter for elements
    if (!flag || flag == "criterion") {
      this.flagFilterBasic(flag, 5, 100, 0, this.maxScorable(flag) * 100, []);
    }
    if (!flag || flag == "tags") {
      this.flagFilterBasic(flag, 3, 100, 0, this.maxScorable(flag) * 100, []);
    }
  }

  /**
   * Apply filter on source data
   * WARNING: This does override data and no rollback is possible
   * @param flag
   * @param filter
   */
  applyUniqueCustomFilter(flag: string, filter: (stat: StatItem) => boolean) {
    this.itemsData.set(flag, this.itemsData.get(flag)
      .filter(filter));
  }
  /**
   * Apply a filter for both criterion and tags
   * @param filter
   * @param flag
   */
  applyBaseFilter(filter?: BaseStatFilter, flag?: string) {
    if (filter) {
      if (this.itemsData.has("criterion") && (!flag || flag == "criterion")) {
        this.flagFilterBasic("criterion", filter.criteriaPenetrationMin, filter.criteriaPenetrationMax,
          filter.criteriaScorableMin, filter.criteriaScorableMax, filter.criteriaBlacklist.map(c => c.id));
      }
      if (this.itemsData.has("tags") && (!flag || flag == "tags")) {
        this.flagFilterBasic("tags", filter.tagPenetrationMin, filter.tagPenetrationMax,
          filter.tagScorableMin, filter.tagScorableMax, filter.tagBlacklist.map(t => t.id));
      }
    } else {
      if (!flag || flag == 'criterion') {
        this.activeFilter.delete('criterion');
        this.itemsData.delete('criterion-filtered');
      }
      if (!flag || flag == 'tags') {
        this.activeFilter.delete('tags');
        this.itemsData.delete('tags-filtered');
      }
    }
  }
  /**
   * @deprecated Major performance issues
   * @param filter
   */
  applyDynamicFilter(filter: BaseStatFilter) {
    this.dynamicFilter = filter;
  }
  /**
   * @Deprecated Major performance issues
   * @param flag
   * @private
   */
  private dynamicFlagFilterBasic(flag: string): StatItem[] {
    const filter = this.dynamicFilter;
    const isCriteria = flag == "criterion";

    const penMin = isCriteria ? filter.criteriaPenetrationMin : filter.tagPenetrationMin;
    const penMax = isCriteria ? filter.criteriaPenetrationMax : filter.tagPenetrationMax;
    const scorableMin = isCriteria ? filter.criteriaScorableMin : filter.tagScorableMin;
    const scorableMax = isCriteria ? filter.criteriaScorableMax : filter.tagScorableMax;

    return this.itemsData.get(flag).filter(s => {
      const pen = s.percent1 * 100;
      const scorable = s.scorable * 100;

      return (pen >= penMin && pen <= penMax) && (scorable >= scorableMin && scorable <= scorableMax);
    })
  }
  /**
   * Apply a filter to a flag, data is store apart as "-filtered" variant
   * @param flag
   * @param penMin
   * @param penMax
   * @param scorableMin
   * @param scorableMax
   * @param filterId
   * @private
   */
  private flagFilterBasic(flag: string, penMin: number, penMax: number, scorableMin: number, scorableMax: number, filterId: number[]) {
    if (this.itemsData.has(flag)) {
      this.activeFilter.set(flag, true);
      this.itemsData.set(flag + "-filtered", this.itemsData.get(flag)
        .filter(s => {
          if (StatHandler.TYPE_WHITELIST.includes(s.type)) return true;

          const percent = s.percent1 * 100;
          const scorable = s.scorable * 100;

          const percentMatch = percent >= penMin && percent <= penMax;
          const selectivityMatch = scorable >= scorableMin && scorable <= scorableMax;

          return percentMatch && selectivityMatch && !filterId.includes(s.id);
        })
      );
    }
  }
  maxScorable(flag: string): number {
    if (this.get(flag, false).length > 0) {
      return this.get(flag, false).sort((a, b) => {
        return b.scorable - a.scorable;
      })[0].scorable
    } else {
      return 2;
    }
  }
  switchScorable(dataType: DataType, flag?: string) {
    const processItem = (i: StatItem) => {
      if (dataType == DataType.SELECTIVITY) {
        i.scorable = i.selectivity
      } else if (dataType == DataType.SCORE) {
        i.scorable = i.score
      } else {
        Logger.logWarning("Illegal data type used for switching sorting score")
      }
    };

    if (flag) {
      this.itemsData.get(flag).forEach(i => processItem(i));
    } else {
      this.itemsData.forEach(items => items.forEach(i => processItem(i)));
    }
  }
  switchSizeSource(sourceType: SourceType) {
    /*this.itemsData.forEach(items => {
      items.forEach(i => {
        if (sourceType == SourceType.SINGLE) {
          i.size1 = i.size1Single;
          i.size2 = i.size2Single;
        }
        if (sourceType == SourceType.LOWER) {
          i.size1 = i.size1Lower;
          i.size2 = i.size2Lower;
        }
        if (sourceType == SourceType.UPPER) {
          i.size1 = i.size1Upper;
          i.size2 = i.size2Upper;
        }
      });
    });*/
    console.warn("WARNING: Switch size feature has been disabled");
  }

  sortByScorable(key: string) {
    this.itemsData.set(key, this.itemsData.get(key).sort((a, b) => b.scorable - a.scorable));
  }

  top(key: string, size: number, sort?: (a: StatItem, b: StatItem) => number) {
    const data = sort ? this.itemsData.get(key).sort(sort).slice(0, size) : this.itemsData.get(key).slice(0, size);
    this.itemsData.set(key, data);
    return this;
  }

  copy() {
    const handler = new StatHandler();
    this.keys().forEach(k => {
      handler.add(k, this.itemsData.get(k));
    });

    return handler;
  }

  toJson() {
    const jsonHandler: any = {};
    this.itemsData.forEach((value, key) => {
      jsonHandler[key] = value;
    });

    return jsonHandler;
  }

  static fromJson(js: any) {
    const handler = new StatHandler();
    Object.entries(js).forEach(([key, value]) => {
      handler.add(key, <any>value);
    });

    return handler;
  }
}
