import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../../services/sp-authentication/authentication.service';
import {ActivatedRoute, NavigationEnd, Router, NavigationStart} from '@angular/router';
import {FilterService} from '../../../services/sp-filter/filter.service';
import {BaseStatFilter, ItemBlacklist} from '../../../classes/filter/base-stat-filter';
import {HeaderOption, NavigationService} from '../../../services/sp-navigation/navigation.service';
import {ProjectManagerService, ProjectSortType} from '../../../services/sp-project/project-manager.service';
import {Audience} from '../../../models/audience';
import {environment} from '../../../../environments/environment';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import {LoaderService} from '../../../services/sp-loading/loader.service';
import {ApiDataService} from '../../../services/sp-api/sp-api-data/api-data.service';
import fileSaver from 'file-saver';
import {ChartService} from '../../../services/sp-chart/chart.service';
import {IntroService} from '../../../services/sp-intro/intro.service';
import {DataType} from '../../../classes/data/stat-handler';
import {EMPTY, Subject, Subscription} from 'rxjs';
import {PersonaFilter, PersonaLocation} from '../../../classes/filter/persona-filter';
import {MatDialog} from '@angular/material/dialog';
import introJs from 'intro.js';
import {AlertService} from '../../../services/sp-alert/alert.service';
import {ApiBotService} from '../../../services/sp-api/sp-api-bot/api-bot.service';
import {HttpClient} from '@angular/common/http';
import {LoggerService} from '../../../services/sp-logger/logger.service';
import {FacebookLinkService} from '../../../services/sp-facebook/facebook-link.service';
import {RoleUtils} from '../../../utils/role/role-utils';
import {Notification} from '../../../models/notification';
import {SocketService} from '../../../services/sp-ws/socket.service';
import {StatItem} from '../../../models/stats/stat-item';
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {CookieService} from "ngx-cookie-service";
import {Link, ReportingManager} from '../../../services/sp-reporting/reporting-manager.service';
import {SpDashboardTabCreationModalComponent} from '../../sp-dashboard/sp-dashboard-tab-creation-modal/sp-dashboard-tab-creation-modal.component';
import {ApiDashboardService} from '../../../services/sp-api/sp-api-dashboard/api-dashboard.service';
import {ActiveToast} from "ngx-toastr";
import {ApiService} from "../../../services/sp-api/api.service";
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {SpCustomThemeService} from '../../../services/sp-custom-logo/sp-custom-theme.service';
import {Targeting} from '../../../classes/targeting/targeting';
import {MatTabLink} from '@angular/material/tabs';
import {ApiUserService} from '../../../services/sp-api/sp-api-user/api-user.service';
import {Dashboard} from '../../../models/dashboard';
import {debounceTime, first, switchMap} from 'rxjs/operators';
import {Folder} from '../../../models/folder';
import {
  SpModalProjectListFolderCreateComponent
} from '../../sp-project/sp-project-list/sp-modal-project-list-folder-create/sp-modal-project-list-folder-create.component';
import {ArrayUtils} from '../../../utils/array-utils';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-sp-layout-dashboard-desk',
  templateUrl: './sp-layout-dashboard-desk.component.html',
  styleUrls: ['./sp-layout-dashboard-desk.component.scss']
})
export class SpLayoutDashboardDeskComponent implements OnInit, OnDestroy, AfterViewInit {
  showFreemiumVideo = true;

  forceHideFacebookBanner = true;
  isFbLogged = false;
  hasUserAllFbScopes = false;
  areUserAdAccountsValid = false;

  autorization;
  loading = false;
  selectedImage: any = null;
  showFree = false;
  showNotificationBanner = false;

  businessRules = [{
    id: "BR1",
    name: "Without any restriction"
  },
  {
    id: "BR2",
    name: "With two SOPRISM segments"
  },
  {
    id: "BR3",
    name: "With one SOPRISM segment and only one sociodemo combination"
  },
  {
    id: "BR4",
    name: "With \"All\" sociodemo and focus on one SOPRISM segment"
  },
  {
    id: "BR5",
    name: "With only on sociodemo segmentation"
  },
  {
    id: "BR6",
    name: "Best Niche segments"
  }];

  selected = this.businessRules[0].id;

  mindsetsActive = false;

  // Criterion related
  rangeValuesPenetrationCriteria: number[] = [5, 100];
  rangeValuesSelectivityCriteria: number[] = [0, 200];
  criteriaBlacklist: ItemBlacklist[] = [];

  // Tags related
  rangeValuesPenetrationTags: number[] = [3, 100];
  rangeValuesSelectivityTags: number[] = [0, 200];
  tagBlacklist: ItemBlacklist[] = [];

  // Sociodemo
  rangeValuesSelectivityAge: number[] = [13, 65];
  genderFilter: number = 4; // 1: Male, 2: Female, 3: Both, 4: All
  locationFilter: PersonaLocation = PersonaLocation.UNDEFINED;

  // Score
  //rangeValuesScore: number[] = [5,50];

  // Target
  targetSize: number [] = [0, 60000000];
  targetSizeMax: number = 60000000;
  targetSizeMin: number = 0;

  // Persona
  segmentSelected: string = "";
  //ageSliderStep: number = 1;

  searchText: string;
  searchProject: string = "";

  env = environment;
  introJSEnding = introJs();

  // Subscriptions
  private subscriptions: Subscription[] = [];

  // Links
  links: Link[] = [];
  // Filter thresholds
  criteriaScorableMaxThreshold: number = 200;
  tagScorableMaxThreshold: number = 200;

  // Filter stat data
  private _criteriaStat: StatItem[] = [];
  private _tagStat: StatItem[] = [];

  // Permissions
  companyManager: boolean = false;
  userAdmin: boolean = false;
  tagAdmin: boolean = false;
  dataExport: boolean = false;
  dashboardAccess: boolean = false;
  metaExportAccess: boolean = false;

  // Bot
  downloading = false;
  filtering = false;
  freemium = false;

  // Notification
  lastNofication: Notification;
  notificationClosed: boolean = false;

  // Blocked user
  userBlockedMessage?: string;

  hideFirstStep = false;
  @ViewChild("filterMenu",{static: true}) filterMenu: MatMenu;
  @ViewChild("matMenuTrigger", {static: true}) matMenuTrigger: MatMenuTrigger;
  @ViewChild('chatBox', {static: true}) chatBox: ElementRef;
  @ViewChild('userMsg',{static: true}) inputName;
  url: string | ArrayBuffer;

  projectSharedName: string = null
  dashboardPath = "";

  private readonly defaultLogo: string = "assets/images/logos/soprism/soprism-logo-sign.png";
  dashboardLogo: string = this.defaultLogo;
  dashboardLogoBase64 = false;

  //User
  isUserSuperAdmin = false;

  filterText: string = "";
  filterTextDashboardList: string = "";
  filterTextProjectList: string = "";

  //Targeting
  exportName?: string;
  exportTargeting?: Targeting;
  exportType;

  dashboardList: Dashboard[] = [];
  templateList: Dashboard[] = [];

  pageName;

  isEditable: boolean;
  sortedProjects;
  selectedProject;

  showSidebarContent;
  private projectFilterChangeSubject: Subject<string> = new Subject();
  isPanelProjectInFolderOpen = false;

  constructor(
    public auth: AuthenticationService,
    public router: Router,
    private route: ActivatedRoute,
    public filterService: FilterService,
    public navigation: NavigationService,
    public projectManager: ProjectManagerService,
    public loader: LoaderService,
    private apiData: ApiDataService,
    private introService: IntroService,
    private dialog: MatDialog,
    private apiBot: ApiBotService,
    private alert: AlertService,
    private fb: FacebookLinkService,
    private logger: LoggerService,
    private socket: SocketService,
    private cookie: CookieService,
    private apiDashboard: ApiDashboardService,
    private reportingMan: ReportingManager,
    private customTheme: SpCustomThemeService,
    private apiUser: ApiUserService,
    private api: ApiService,
    private overlayContainer: OverlayContainer
  ) {

    this.introJSEnding.setOptions({
      steps: [
        {
          element: '#endIntro',
          intro: 'You can <strong> reactivate </strong> this <strong>guide </strong> at any time by <strong>clicking on this button</strong>',
          position: 'bottom',
        },
      ],
      showProgress: false,
      disableInteraction: false,
      hideNext: true,
      highlightClass: 'sp-helperLayer-insight'
    });

    router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        const content = document.getElementById('sp-content');
        if (content) { content.style.height = String(document.documentElement.scrollHeight); }

        const sidebar = document.getElementById('sp-sidebar');
        if (sidebar) { sidebar.style.height = String(document.documentElement.clientHeight); }
      }
    });
  }

  ngOnInit() {
    if (environment.config.socket.enabled) {
      this.socket.createListener().subscribe(sData => {
        const onTapEvent = () => {
          this.router.navigate(['admin', 'events']).then(() => {
            setTimeout(() => {
              this.socket.activeEvent.next(sData);
            }, 1000)
          });
        }

        if (sData.type == "user-list") {
          this.logger.debug(sData.data.length + " user(s) actually connected.");
        } else if (sData.type == "user-connected" && sData.data.id != this.auth.session.user.id) {
          this.socketNotification("User Connected",
            sData.data.first_name + " " + sData.data.last_name + " (" + sData.data.email + ") is connected to the dashboard",
            "info")?.onTap.subscribe(onTapEvent);
        } else if (sData.type == "user-disconnected") {
          this.socketNotification("User Disconnected",
            sData.data.first_name + " " + sData.data.last_name + " (" + sData.data.email + ") is disconnected from the dashboard",
            "info")?.onTap.subscribe(onTapEvent);
        } else if (sData.type == "user-error") {
          this.socketNotification('User Error', 'User ' + sData.data.user.email + ' encountered an error', 'error')
            ?.onTap.subscribe(onTapEvent);
        } else if (sData.type == 'user-project-create') {
          this.socketNotification('User Project Create', 'User ' + sData.data.user.email + ' created a project named ' + sData.data.projectName, 'info')
            ?.onTap.subscribe(onTapEvent);
        }
      });
    }
    //Targeting

    this.route.queryParamMap.subscribe(params => {
      if (params.has('targeting') && params.has('name')) {
        this.exportName = params.get('name');
        this.exportTargeting = Targeting.fromJson(params.get('targeting'), 'export');
        this.exportType = params.get('type');

      }
    });

    this.freemium = this.auth.session.user?.role.level <= RoleUtils.freemiumLevel;
    this.subscriptions.push(this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd && this.freemium) {
        this.showFree = true;
      }
    }));
    this.showFree = this.freemium;
    this.auth.permissionCheck('user.admin').then(authorized => {
      this.userAdmin = authorized;
    });

    this.auth.permissionCheck('tag.admin').then(authorized => {
      this.tagAdmin = authorized;
    });
    this.auth.permissionCheck('company.self.manage').then(authorized => {
      this.companyManager = authorized;
    });
    this.auth.permissionCheck('data.export').then(authorized => {
      this.dataExport = authorized;
    });
    this.auth.permissionCheck('dashboard.access').then(authorized => {
      this.dashboardAccess = authorized;
    });
    this.auth.permissionCheck('targeting.export').then(authorized => {
      this.metaExportAccess = authorized;
    });

    if (this.auth.session.authenticated && this.auth.session.user.role.level >= RoleUtils.superAdminLevel) this.isUserSuperAdmin = true;

    this.introService.introOver$.subscribe(introOver => {
      if (introOver) {
        this.introJSEnding.start();
      }
    });

    this.filterService.onThresholdChange.subscribe(threshold => {
      switch (threshold.flag) {
        case "criterion":
          this.criteriaScorableMaxThreshold = threshold.value;
          this.rangeValuesSelectivityCriteria = [this.rangeValuesSelectivityCriteria[0], threshold.value];
          this.filter.criteriaScorableMax = threshold.value;
          break;
        case "tags":
          this.tagScorableMaxThreshold = threshold.value;
          this.rangeValuesSelectivityTags = [this.rangeValuesPenetrationTags[0], threshold.value];
          this.filter.tagScorableMax = threshold.value;
          break;
        case "segment-min":
          this.targetSizeMin = threshold.value;
          this.targetSize[0] = threshold.value;
          break;
        case "segment-max":
          this.targetSizeMax = threshold.value;
          this.targetSize[1] = threshold.value;
          break;
        default:
          this.logger.logWarning("Threshold for flag " + threshold.flag + " is not supported");
      }
    });

    this.subscriptions.push(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.matMenuTrigger.closeMenu();
      }
    }));

    if (this.auth.session.authenticated) {
      this.getToken().then();
      this.fb.isLogged().then(async isLogged => {
        const scopesOk = await this.fb.checkAllScopesAreValid();
        this.isFbLogged = isLogged;
        this.hasUserAllFbScopes = scopesOk;
        this.areUserAdAccountsValid = !this.auth.session.user.has_invalid_ad_accounts;
      });
      this.freemium = this.auth.session.user.role.level <= RoleUtils.freemiumLevel;
    }

    this.subscriptions.push(this.navigation.notificationTrigger.subscribe(n => {
      if (!n) {
        this.showNotificationBanner = false;
      } else {
        this.lastNofication = n;
        const now = new Date();
        const startAt = new Date(n.start_on);
        const endAt = new Date(n.end_on);
        if (now >= startAt && now <= endAt && n.active && !this.notificationClosed) {
          this.showNotificationBanner = true;
        } else if (this.showNotificationBanner) {
          this.showNotificationBanner = false;
        }
      }
    }));

    this.subscriptions.push(this.navigation.blockedUserMessageTrigger.subscribe(msg => {
      this.userBlockedMessage = msg;
    }));
    this.subscriptions.push(this.projectManager.projectDataLoadedListener.subscribe(data => {
      this._criteriaStat = data.get('criterion');
      this._tagStat = data.get('tags');
    }));

    if (this.projectManager.selectedProject) {
      this.dashboardPath = "link/"+ this.projectManager.selectedProject.id;
    }

    // BAH NON DU COUP C'EST ICI
    this.subscriptions.push(this.customTheme.onLogoChange(img => {
      if (img) {
        this.dashboardLogo = img.image;
        this.dashboardLogoBase64 = img.b64;
      } else {
        this.dashboardLogo = this.defaultLogo;
      }
    }));


    // C EST ICI POUR CHANGER LE LOGO
    /*if(this.customLogo.selectedImage) {
      this.dashboardLogo = //this.customLogo.selectedImage;
    }*/
    if (this.auth.session.authenticated) {
      this.projectManager.loadFolders();
      this.loadDashboards()
      this.loadTemplates();
    }


    if (this.router.url.includes("insights/socio-demo")) {
      this.pageName = "Socio-Demo";
    } else if(this.router.url.includes('insights/topics')) {
      this.pageName = "Topics";
    } else if (this.router.url.includes("insights/overview")) {
      this.pageName = "Interest";
    } else if (this.router.url.includes("insights/maps")) {
      this.pageName = "Maps";
    } else if (this.router.url.includes("persona/overview")) {
      this.pageName = "Overview";
    } else if(this.router.url.includes("persona/manager")) {
      this.pageName = "Segmentation";
    }else if(this.router.url.includes("admin/companies")) {
      this.pageName = "Companies";
    }else if(this.router.url.includes("admin/users")) {
      this.pageName = "Users";
    }else if(this.router.url.includes("admin/notification")) {
      this.pageName = "Notification";
    }else if(this.router.url.includes("admin/events")) {
      this.pageName = "Events";
    }else if(this.router.url.includes("admin/parameters")) {
      this.pageName = "Parameters";
    }else if(this.router.url.includes("admin/projects")) {
      this.pageName = "Projects";
    }else if(this.router.url.includes("account/edit")) {
      this.pageName = "Manage my profile";
    }else if(this.router.url.includes("/projects/create/select")) {
      this.pageName = "Select the audience type you want to profile";
    }else if(this.router.url.includes("/projects/create/facebook")) {
      this.pageName = "Create your project's audiences";
    }else if(this.router.url.includes("/dashboard/reporting/widget")) {
      this.pageName = "Create a new widget";
    }else if(this.router.url.includes("/projects/list")) {
      this.pageName = "Welcome back" + this.auth.session.user.first_name;
    }

    const typeFilterSub = this.typeFilterChangeSubject.pipe(
      debounceTime(500),
      switchMap(types => {
        this.onProjectTypeFilterConfirm(types);
        return EMPTY;
      })
    ).subscribe();
    this.subscriptions.push(typeFilterSub);

    this.subscriptions.push(this.reportingMan.linkCreatedSub(link => {
      this.links.push(link);
    }));
    this.subscriptions.push(this.reportingMan.linkDeletedSub(link => {
      this.links.splice(this.links.indexOf(link), 1);
    }));
    this.subscriptions.push(this.reportingMan.linkOverrideSub(links => {
      this.links = links;
    }));
    this.subscriptions.push(this.navigation.reportingChangeListener.subscribe(reporting => {
      this.ngOnDestroy();
      this.ngOnInit();
    }));

    // Triez le tableau des projets par nom
    this.sortedProjects = this.projectManager.allProjects.slice().sort((a, b) => a.name.localeCompare(b.name));
    this.initSidebarContent();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  updateSlider() {
   document.getElementById("ageSlider").onreset;
  }

  toggleDebugMode() {
    environment.config.enableDebugFeatures = !environment.config.enableDebugFeatures;
    if (environment.config.enableDebugFeatures) {
      this.cookie.set("debug", "on", 0, "/", undefined, true, "Strict");
    } else {
      this.cookie.delete("debug", "/");
    }
  }

  socketNotification(title: string, message: string, type: string): ActiveToast<any>|undefined {
    if (this.socket.notifications) {
      return this.alert.notify(title, message, type);
    }
  }

  handleMenuEvent(event: Event) {
    event.stopPropagation();
  }

  closeFreemium(){
    this.showFreemiumVideo = false;
  }
  openFreemium() {
    this.showFreemiumVideo = true;
  }

  toNumberFormat(value: number) {
    return ChartService.separator(value);
  }

  /// Import all dashboards
  loadDashboards() {
    this.apiUser.getDashboards(this.auth.session.user).then(list => {
      this.dashboardList = list.filter(d => d.preset !== true);
      this.dashboardList.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    });
  }

  goToDashboard(dash:Dashboard) {
    this.router.navigate(['dashboard','reporting', 'list', this.projectManager.selectedProject.id, 'insight', dash.id]).then(()=> {
      this.navigation.notifyReportingChange(dash);
    });
  }


  goToMetaExport() {
    const project = this.projectManager.selectedProject;
    const params: any = {
      'name': project.name,
      'targeting': project.audience_target.target_spec,
      'type': 'project'
    };

    if (project.audience_target.type == 'custom' || project.audience_target.type == 'fanpage') {
      const extra = JSON.parse(project.audience_target.data_extra);
      params.adAccountId = extra.accountId;
      params.adAccountName = extra.accountName;
      params.audienceType = project.audience_target.type;
    }

    this.router.navigate(['targeting'], {queryParams: params}).then();
  }

  logout() {
    this.router.navigate(['user/login']).then(() => this.auth.sessionClear());
  }

  audienceSize(audience: Audience) {
    if (audience.latest_crawl) {
      return this.toNumberFormat(audience.latest_crawl.audience_size)
    } else {
      return "N/A"
    }
  }

  private filter: BaseStatFilter = {
    // Criterion
    criteriaPenetrationMin: this.rangeValuesPenetrationCriteria[0],
    criteriaPenetrationMax: this.rangeValuesPenetrationCriteria[1],
    criteriaScorableMin: this.rangeValuesSelectivityCriteria[0],
    criteriaScorableMax: this.rangeValuesSelectivityCriteria[1],
    criteriaBlacklist: this.criteriaBlacklist,

    // Tags
    tagPenetrationMin: this.rangeValuesPenetrationTags[0],
    tagPenetrationMax: this.rangeValuesPenetrationTags[1],
    tagScorableMin: this.rangeValuesSelectivityTags[0],
    tagScorableMax: this.rangeValuesSelectivityTags[1],
    tagBlacklist: this.tagBlacklist
  };

  applyBaseStatFilter() {
    this.filter = {
      // Criterion
      criteriaPenetrationMin: this.rangeValuesPenetrationCriteria[0],
      criteriaPenetrationMax: this.rangeValuesPenetrationCriteria[1],
      criteriaScorableMin: this.rangeValuesSelectivityCriteria[0],
      criteriaScorableMax: this.rangeValuesSelectivityCriteria[1],
      criteriaBlacklist: this.criteriaBlacklist,

      // Tags
      tagPenetrationMin: this.rangeValuesPenetrationTags[0],
      tagPenetrationMax: this.rangeValuesPenetrationTags[1],
      tagScorableMin: this.rangeValuesSelectivityTags[0],
      tagScorableMax: this.rangeValuesSelectivityTags[1],
      tagBlacklist: this.tagBlacklist
    };

    this.filterService.baseStatFilterRequest(this.filter)
  }

  cancelFilter() {
    // Criterion related
    this.rangeValuesPenetrationCriteria = [this.filter.criteriaPenetrationMin, this.filter.criteriaPenetrationMax];
    this.rangeValuesSelectivityCriteria = [this.filter.criteriaScorableMin, this.filter.criteriaScorableMax];

    // Tags related
    this.rangeValuesPenetrationTags = [this.filter.tagPenetrationMin, this.filter.tagPenetrationMax];
    this.rangeValuesSelectivityTags = [this.filter.tagScorableMin, this.filter.tagScorableMax];
  }

  /*
   * MINDSET PARAMETER ALLOWS YOU TO TOGGLE MINDSET SEGMENTS FOR THE NEW FILTER
   */
  applyPersonaFilter() {
    const filter: PersonaFilter = {
      targetMin: this.targetSize[0],
      targetMax: this.targetSize[1],
      ageMin: this.rangeValuesSelectivityAge[0],
      ageMax: this.rangeValuesSelectivityAge[1],
      gender: this.genderFilter,
      location: this.locationFilter,
      segment: this.segmentSelected,
      businessRule: this.selected,
      mindsets: this.mindsetsActive
    };

    this.filterService.personaFilterRequest(filter);
  }

  onSwitchChange(defaultVal: boolean) {
    // Reset dynamic filter
    this.filterService.baseStatFilterRequest(null);

    const newDataType = this.env.config.switchDataType == "score" ?
      (defaultVal ? DataType.SELECTIVITY : DataType.SCORE) :
      (defaultVal ? DataType.NORMALIZED : DataType.RAW)
    // Trigger event for data type
    this.navigation.dataTypeChangeListener.next(newDataType);
  }

  downloadExcel() {
    const project = this.projectManager.selectedProject;
    this.downloading = true;
    if (project) {
      let normalized = true;
      if (this.navigation.currentDataType == DataType.NORMALIZED || this.navigation.currentDataType == DataType.RAW) {
        normalized = this.navigation.currentDataType == DataType.NORMALIZED;
      }
      this.apiData.getDataExcel(project, normalized).then(excelData => {
        this.socket.sendMessageType('excel-export', {projectName: project.name})
        let blob:any = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8' });

        fileSaver.saveAs(blob, project.name + '.xlsx');
      }).catch(error => {
        if (error.status == 403) {
          this.alert.notify('Access Denied', 'You are not able to export data', 'error');
        }
      }).finally(() => this.downloading = false);
    }
  }
  downloadCSV() {
    const project = this.projectManager.selectedProject;
    this.downloading = true;
    if (project) {
      let normalized = true;
      if (this.navigation.currentDataType == DataType.NORMALIZED || this.navigation.currentDataType == DataType.RAW) {
        normalized = this.navigation.currentDataType == DataType.NORMALIZED;
      }
      this.apiData.getDataCSV(project, normalized).then(excelData => {
        this.socket.sendMessageType('csv-export', {projectName: project.name})
        let blob:any = new Blob([excelData], { type: 'application/zip; charset=utf-8' });

        fileSaver.saveAs(blob, project.name + '.zip');
      }).catch(error => {
        if (error.status == 403) {
          this.alert.notify('Access Denied', 'You are not able to export data', 'error');
        }
      }).finally(() => this.downloading = false);
    }
  }

  startIntroJs(){
    document.documentElement.scrollTop = 0;
    this.introService.autoStart = false;
    this.introService.startIntroService(true);
    this.hideFirstStep = true;
  }

  scorableToString() {
    return this.navigation.currentDataType == DataType.SELECTIVITY ? "Selectivity" : "Score";
  }

  resetData(){
    // Criterion related
    this.rangeValuesPenetrationCriteria = [5, 100];
    this.rangeValuesSelectivityCriteria = [0, this.criteriaScorableMaxThreshold];

    // Tags related
    this.rangeValuesPenetrationTags = [3, 100];
    this.rangeValuesSelectivityTags = [0, this.tagScorableMaxThreshold];

    this.applyBaseStatFilter();

  }

  updateGender(gender: number) {
    if ((gender == 1 && this.genderFilter == 2) || (gender == 2 && this.genderFilter == 1)) {
      this.genderFilter = 3;
    } else if (gender == 1 && this.genderFilter == 3) {
      this.genderFilter = 2;
    } else if (gender == 2 && this.genderFilter == 3) {
      this.genderFilter = 1;
    } else {
      this.genderFilter = gender;
    }
    this.mindsetsActive = false;
  }

  onTagBlacklistSelect(tag: StatItem) {
    if (!this.tagBlacklist.find(t => t.id == tag.id)) {
      this.tagBlacklist.push({id: tag.id, name: tag.name});
    }/* else {
      this.tagBlacklist = this.tagBlacklist.filter(t => t.id != tag.id);
    }*/
  }

  onTagBlacklistRemove(tag: ItemBlacklist) {
    this.tagBlacklist.splice(this.tagBlacklist.indexOf(tag), 1);
  }


  onCriteriaBlacklistSelect(criteria: StatItem) {
    if (!this.criteriaBlacklist.find(c => c.id == criteria.id)) {
      this.criteriaBlacklist.push({id: criteria.id, name: criteria.name});
    } else {
      this.criteriaBlacklist = this.criteriaBlacklist.filter(c => c.id != criteria.id);
    }
  }

  onCriteriaBlacklistRemove(criteria: ItemBlacklist) {
    this.criteriaBlacklist.splice(this.criteriaBlacklist.indexOf(criteria), 1);
  }

  closeMatPanel(panel: MatAutocompleteTrigger) {
    setTimeout(() => {
      panel.closePanel();
    }, 300);
  }

  openMatPanel(panel: MatAutocompleteTrigger) {
    setTimeout(() => {
      panel.openPanel();
    }, 400);
  }

  getToken(){
      return this.apiBot.getGoogleToken().then(token => this.autorization = "Bearer " + token);
  }

  statToString(stat: StatItem) {
    return stat.name;
  }

  hideFacebookLoginBanner() {
    this.forceHideFacebookBanner = true;
  }

  hideBanner() {
    this.showFree = false;
  }
  hideInfoBanner() {
    this.showNotificationBanner = false;
    this.notificationClosed = true;
  }

  @ViewChild('newName',{static: true}) newTabName;

  openDashboardTabCreation(): void {
    let doNotOpen = false;

    const tabChoice = (type: string): boolean => {
      if (type === "tab") {
        this.reportingMan.createLink("New tab");
        return true;
      } else if (type === "template") {
        const project = this.projectManager.selectedProject;
        this.router.navigate(['dashboard','reporting', 'template', project.id], {queryParams: {'dash_id': this.reportingMan.loadedDashboard.id}}).then();
        return true;
      } else return false;
    }

    const choiceStored = localStorage.getItem("new-tab-modal-choice");
    if (choiceStored) {
      doNotOpen = tabChoice(choiceStored);
    }

    if (!doNotOpen) {
      this.dialog.open(SpDashboardTabCreationModalComponent, {
        autoFocus: false,
        minHeight: '30rem',
        minWidth: '50rem',
        maxHeight: '60rem',
        maxWidth: '50rem',

      }).afterClosed().subscribe(result => {
        if (result) {
          const type = result.type;

          if (result.doNotShowAgain && type) localStorage.setItem("new-tab-modal-choice", type);
          tabChoice(type);
        }
      });
    }
  }

  get tagStat(): StatItem[] { return this._tagStat.filter(t => !this.tagBlacklist.find(tt => tt.id == t.id)); }
  get criteriaStat(): StatItem[] { return this._criteriaStat.filter(c => !this.criteriaBlacklist.find(cc => cc.id == c.id)); }

  // we create an object that contains coordinates
  menuTopLeftPosition =  {x: 0, y: 0}

  // reference to the MatMenuTrigger in the DOM
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger2: MatMenuTrigger;

  /**
   * Method called when the user click with the right button
   * @param event MouseEvent, it contains the coordinates
   * @param item Our data contained in the row of the table
   */

  tabClicked;
  showEditMode = false;


  @HostListener('window:resize', []) onWindowScroll() {
    const element = document.getElementById("sp-header-title-v2");
    if (element) element.style.display = "block";
  }

  onRightClick(event: MouseEvent, item) {
    if (!this.router.url.includes('dashboard/reporting/shared')) {
      // preventDefault avoids to show the visualization of the right-click menu of the browser
      event.preventDefault();
      this.tabClicked = item
      // we record the mouse position in our object
      this.menuTopLeftPosition.x = event.clientX;
      this.menuTopLeftPosition.y = event.clientY;
      // we open the menu
      // we pass to the menu the information about our object
      this.matMenuTrigger2.menuData = {item: item}

      // we open the menu
      this.matMenuTrigger2.openMenu();
    }
  }

  deleteTab(){
    this.reportingMan.deleteLink(this.tabClicked);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.links, event.previousIndex, event.currentIndex);
    this.reportingMan.loadLinks(this.links);
    this.reportingMan.linkUpdated();
  }

  setLinkActive(link: Link) {
    this.reportingMan.linkSetActive(link);
    this.showEditMode = false;
  }

  editTab() {
    this.showEditMode = true;
    const input: HTMLInputElement = <HTMLInputElement>document.getElementById("tab-"+this.tabClicked.id);
    const inputEnd = input.value.length;
    input.focus();
    input.setSelectionRange(inputEnd,inputEnd);
  }

  protected readonly HTMLInputElement = HTMLInputElement;

  goDataManager() {
    window.location.href='https://control-dev.soprism.com/login';
  }

  changeTabName(newName : MatTabLink){
    const input = <HTMLInputElement>newName.elementRef.nativeElement;
    const newLinkName = input.value;
    const link = this.links.find(x => x.id == this.tabClicked.id);
    link.name = newLinkName;
    this.showEditMode = false;
    this.reportingMan.updateLink(link);
  }

   async loadTemplates() {
     this.templateList = await this.apiDashboard.presets();
   }

  viewTemplate(template: Dashboard, edit: boolean = false) {
    this.router.navigate(['dashboard','reporting' ,'list', this.projectManager.selectedProject.id, 'insight', template.id], {queryParams: edit ? {} : {templateView: 1}}).then(() => {
      this.navigation.notifyReportingChange(template);
    });
  }

    navigateTo(header:HeaderOption)
    {
      this.navigation.dontRefreshNext = true;
      this.router.navigate([header.url], header.queryParams ? {queryParams: header.queryParams} : undefined).then();
      this.pageName = header.viewValue;
    }

  changeSidebarContent(sidebarIconHover: string){
    if(sidebarIconHover == "Project list") {
      this.showSidebarContent = "Project list"
    }else if(sidebarIconHover == "Insights") {
      this.showSidebarContent = "Insights"
    }else if(sidebarIconHover == "Persona") {
      this.showSidebarContent = "Persona"
    }else if(sidebarIconHover == "Tag") {
      this.showSidebarContent = "Tag"
    }else if(sidebarIconHover == "Data manager") {
      this.showSidebarContent = "Data manager"
    }else if(sidebarIconHover == "Reporting") {
      this.showSidebarContent = "Reporting"
    }
  }

  initSidebarContent() {
    if (this.router.url.includes("insights/socio-demo")) {
      this.showSidebarContent = "Insights"
    } else if(this.router.url.includes('insights/topics')) {
      this.showSidebarContent = "Insights"
    } else if (this.router.url.includes("insights/overview")) {
      this.showSidebarContent = "Insights"
    } else if (this.router.url.includes("insights/maps")) {
      this.showSidebarContent = "Insights"
    } else if (this.router.url.includes("persona/overview")) {
      this.showSidebarContent = "Persona"
    } else if(this.router.url.includes("persona/manager")) {
      this.showSidebarContent = "Persona"
    }else if(this.router.url.includes("admin/companies")) {
      this.pageName = "Companies";
    }else if(this.router.url.includes("admin/users")) {
      this.pageName = "Users";
    }else if(this.router.url.includes("admin/notification")) {
      this.pageName = "Notification";
    }else if(this.router.url.includes("admin/events")) {
      this.pageName = "Events";
    }else if(this.router.url.includes("admin/parameters")) {
      this.pageName = "Parameters";
    }else if(this.router.url.includes("admin/projects")) {
      this.pageName = "Projects";
    }else if(this.router.url.includes("account/edit")) {
      this.pageName = "Manage my profile";
    }else if(this.router.url.includes("/projects/create/select")) {
      this.pageName = "Select the audience type you want to profile";
    }else if(this.router.url.includes("/projects/create/facebook")) {
      this.pageName = "Create your project's audiences";
    }else if(this.router.url.includes("/dashboard/reporting/widget")) {
      this.showSidebarContent = "Reporting"
    }else if(this.router.url.includes("/projects/list")) {
      this.showSidebarContent = "Project list"
    }
  }

  projectSelectionSideMenuChange(event: any) {
    let id: number;
    const valueRaw = event.target.value;
    if (valueRaw) id = parseInt(valueRaw);

    if (id) {
      const project = this.projectManager.allProjects.find(p => p.id == id);
      if (project) {
        this.projectManager.selectedProjectShortcut = project;
        this.navigation.notifyProjectChange(project);
      }
    }
  }

  /// For project list
  projectReadyCount = 0;
  typeFiltering: string[] = ['project', 'project_shared', 'folder'];
  sortType: ProjectSortType = ProjectSortType.CREATION_DATE;
  canSeeCustomFolder = false;
  // Variable
  private typeFilterChangeSubject: Subject<string[]> = new Subject();

  get ProjectSortType() {
    return ProjectSortType;
  }

  isProjectSearchClicked = false;

  // Method
  openCreateFolderDialog(): void {
    this.filterText = "";
    const dialogRef = this.dialog.open(SpModalProjectListFolderCreateComponent, {
      minHeight: '10rem', minWidth: '30rem', maxHeight: '30rem', maxWidth: '60rem'
    });

    const sub = dialogRef.afterClosed().subscribe(folder => {
      if (folder) {
        this.projectManager.addFolder(folder, false);
      }
      sub.unsubscribe();
    });
  }

  selectFolder(folder: Folder) {
    this.filterText = "";
    this.projectReadyCount = 0;

    this.projectManager.selectFolder(folder);
    this.projectManager.reloadProjectChunks(this.filterText, this.typeFiltering);
    setTimeout(() => {
    }, 200);
  }

  changeSortType(sortType: ProjectSortType) {
    this.projectReadyCount = 0;
    this.sortType = sortType;
    this.projectManager.unloadProjectChunks();
    this.projectManager.loadProjectChunk(this.sortType, this.filterText, this.typeFiltering).then();
  }

  onTypeFilterChange(newVal: string[]) {
    //this.loader.load();
    this.typeFilterChangeSubject.next(newVal);
  }
  isPanelProjectOpen = false;
  isPanelFolderOpen = false;
  isPanelDashboardOpen = false;
  isPanelTemplateOpen = false;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.projectSharedName = this.reportingMan.loadName();
    },1500);

    if (this.auth.session.authenticated) {
      this.api.getParam('intercom').then(param => {
        if (param.value == 'enabled') {
          if (environment.config.intercom.enabled) {
            (<any>window).Intercom("boot", {
              api_base: environment.config.intercom.apiBase,
              app_id: environment.config.intercom.appId,
              name: this.auth.session.user.first_name + ' ' + this.auth.session.user.last_name,
              email: this.auth.session.user.email,
              user_id: this.auth.session.user.id.toString(),
              user_hash: this.auth.session.user.intercom_hash,
              created_at: Math.floor(new Date(this.auth.session.user.created_at).getTime() / 1000)
            });

            if (this.auth.session.user.first_connection) {
              this.socket.sendMessageType('user-first-connection', {});
            }
          }
        }
      });
    }
    this.navigation.reportingEditable.subscribe(edit => {
      this.isEditable = edit;
    })
    this.selectedProject = this.projectManager.selectedProjectShortcut
  }
  private onProjectTypeFilterConfirm(types: string[]) {
    if (!ArrayUtils.isSameContentIgnoringOrder(this.typeFiltering, types)) {
      this.typeFiltering = types;
      this.projectManager.projectTypeFilterListener.next(this.typeFiltering);
    }
  }
  get loadedDashboard(): Dashboard|undefined { return this.reportingMan.loadedDashboard; }

  changeSortTypeDash(sortType: ProjectSortType) {
    this.navigation.dashboardListSortListener.next(sortType);
  }

  onDashboardSearch(search: string) {
    this.navigation.dashboardListSearchListener.next(search)
  }

  /// Menu tools functionality
  sendInfoToReporting(report: string) {
    this.navigation.reportingMenuTools.next(report);
  }


  openSidebar() {
    const sidebar = document.getElementById("sp-sidebar");
    if (sidebar) sidebar.style.width = "396px";
    document.getElementById("sp-project-name-sidebar").style.display = "flex";
    document.getElementById("sp-menu-inside").style.display = "block";
    document.getElementById("sp-account-user-icons").style.display = "flex";
    document.getElementById("sp-account-user-information").style.display = "flex";
    document.getElementById("sp-sidebar-account").style.display = "flex";
  }

  closeSidebar() {
    document.getElementById("sp-sidebar").style.width = "96px";
    document.getElementById("sp-project-name-sidebar").style.display = "none";
    document.getElementById("sp-menu-inside").style.display = "none";
    const accountUserIcons = document.getElementById("sp-account-user-icons");
    if (accountUserIcons) accountUserIcons.style.display = "none";
    document.getElementById("sp-account-user-information").style.display = "none";
    document.getElementById("sp-sidebar-account").style.display = "none";
  }

  detectClickInsideOverlay(isClicked: boolean): void {
    this.isProjectSearchClicked = isClicked;
    this.overlayContainer.getContainerElement().addEventListener('mousemove', (event) => {
      if (this.isProjectSearchClicked) {
        this.openSidebar();
        this.initSidebarContent();
      }
      this.isProjectSearchClicked = false;
      event.stopPropagation();
    });
  }

  /**
   * Event - On project search input update
   * @param search
   */
  onProjectSearch(search: string) {
    this.projectFilterChangeSubject.next(search);
    this.projectManager.projectListSearchListener.next(search);
  }
  onProjectSearchSidebar(search: string) {
    if (!search.trim()) {
      this.isPanelProjectOpen = false;
      this.isPanelFolderOpen = false;
      this.isPanelProjectInFolderOpen = false;
    } else {
      this.openPanelIfProjectFound(search);
      this.projectFilterChangeSubject.next(search);
    }
  }


  resetProjectSearch() {
    this.isPanelProjectOpen = false;
    this.isPanelFolderOpen = false;
  }

  onDashboardSearchList(search: string) {
    if (!search.trim()) {
      this.isPanelDashboardOpen = false;
      this.isPanelTemplateOpen = false;
    } else {
      this.openPanelIfDashboardFound(search);

    }
  }

  resetDashboardSearchList() {
    this.isPanelDashboardOpen = false;
    this.isPanelTemplateOpen = false;
    this.isPanelProjectInFolderOpen = false;
  }

  openPanelIfProjectFound(projectName: string) {
    this.isPanelProjectOpen = this.projectManager.allProjects.some(project => project.name.includes(projectName));
     this.folders.forEach(folder => {
       this.isPanelProjectInFolderOpen = folder.projects.some(project => project.name.includes(projectName));
       if(this.isPanelProjectInFolderOpen) {
         this.isPanelFolderOpen = true;
       }
    });
  }
  openPanelIfDashboardFound(dashboardName: string) {
      this.isPanelDashboardOpen = this.dashboardList.some(dashboard => dashboard.name.includes(dashboardName));
      this.isPanelTemplateOpen = this.templateList.some(dashboard => dashboard.name.includes(dashboardName));

  }

  restoreSelectedProject() {
    if (this.selectedProject) {
      this.projectManager.selectedProjectShortcut = this.selectedProject;
    }
  }

  folderContainsMatchingProject(folder: any): boolean {
    if (folder.projects.some(project => project.name.includes(this.filterText))) {
      this.isPanelProjectInFolderOpen = true;
      return folder.projects.some(project => project.name.includes(this.filterText));
    }

  }

  folderNameMatchesSearch(folder: any): boolean {
    if (this.filterText) {
      const searchText = this.filterText;
      const folderName = folder.name;

      return folderName.includes(searchText);
    }
    return false;
  }

  isOptionVisible(project: any): boolean {
    return project.name.includes(this.searchProject);
  }

  goToFolder(folder: Folder) {
    this.filterText = "";
    this.projectReadyCount = 0;

    this.projectManager.selectFolder(folder);
    this.projectManager.reloadProjectChunks(this.filterText, this.typeFiltering);
    setTimeout(() => {
    }, 200);
  }

  get showLogInToFacebookBanner() {
    return !this.forceHideFacebookBanner && (this.auth.session.authenticated && this.auth.session.user.has_invalid_ad_accounts || !this.isFbLogged || !this.hasUserAllFbScopes);
  }

  get folders(): Folder[] {
    return this.projectManager.folders;
  }
}
