import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {SpThemingService} from '../../../../services/sp-theming/sp-theming.service';
import {MatSort} from '@angular/material/sort';
import {ApiPlanService} from '../../../../services/sp-api/sp-api-plan/api-plan.service';
import {Plan} from '../../../../models/plan';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from '../../../../models/subscription';
import {Company} from '../../../../models/company';
import {ApiCompanyService} from '../../../../services/sp-api/sp-api-company/api-company.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../../models/user';
import {ApiUserService} from '../../../../services/sp-api/sp-api-user/api-user.service';
import {MatDialog} from '@angular/material/dialog';
import {SpModalCompanyAddUsersComponent} from './sp-modal-company-add-users/sp-modal-company-add-users.component';
import {Role} from '../../../../models/role';
import {ApiRoleService} from '../../../../services/sp-api/sp-api-role/api-role.service';
import {AuthenticationService} from '../../../../services/sp-authentication/authentication.service';
import {environment} from '../../../../../environments/environment';
import {Message} from 'primeng/api';
import _ from 'lodash';
import {RoleUtils} from '../../../../utils/role/role-utils';
import {LoaderService} from '../../../../services/sp-loading/loader.service';
import {AlertService} from '../../../../services/sp-alert/alert.service';
import {SocketService} from '../../../../services/sp-ws/socket.service';
import {SpModalUserDeleteComponent} from '../../../sp-modal/sp-modal-user-delete/sp-modal-user-delete.component';

@Component({
  selector: 'app-sp-admin-company-create',
  templateUrl: './sp-admin-company-create.component.html',
  styleUrls: ['./sp-admin-company-create.component.scss']
})

export class SpAdminCompanyCreateComponent implements OnInit {
  userSearchText= '';
  env = environment;
  plans: Array<Plan> = [];
  selectedPlan: Plan = null;
  customPlan: Plan = new Plan("Custom Plan", 0, 0, []);

  userListDisplayedColumns: string[] = [
    'image',
    'last_name',
    'email',
    'type',
    'actions',
  ];

  paymentOptions: {value: string, label: string}[] = [
    {value: "On demand", label: "One time payment"},
    {value: "On license", label: "Monthly payment"},
    {value: "Free", label: "Free user subscription"},
    {value: "Trial", label: "Trial user subscription"}
  ];

  msgs: Message[] = [];
  isNotCreated = true;
  switchRolePending = false;
  editMode = false;
  value = 0;

  monthlyCredits = 0;
  nbrUsers = 0;
  extraCredits = 0;

  userDataSource = new MatTableDataSource<User>([]);
  roles: Array<Role> = [];

  selectedImage: any = null;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  companyFormGroup: UntypedFormGroup;
  subscriptionStartDate: UntypedFormControl;
  subscriptionEndDate: UntypedFormControl;

  // User IDs of already resent invitation
  invitationSent: number[] = [];
  invitationPending: number[] = [];
  // Permissions
  canManageCompany: boolean = false;
  canManageUser: boolean = false;
  isCompanyAdmin: boolean = false;
  isUserAdmin: boolean = false;

  // Url
  backUrl: string;

  // Agency management
  protected companyDataSource: MatTableDataSource<Company> = new MatTableDataSource<Company>([]);
  protected companyManagedDisplayColumns: string[] = ['id', 'name', 'actions'];
  protected allCompanies: Company[] = [];
  protected allCompaniesFiltered: Company[] = [];
  protected selectedCompany?: Company;

  // Constructor.
  constructor(private _spTheming: SpThemingService,
              private apiPlan: ApiPlanService,
              private apiCompany: ApiCompanyService,
              private apiUser: ApiUserService,
              private apiRole: ApiRoleService,
              public auth: AuthenticationService,
              private router: Router,
              private dialog: MatDialog,
              private loader: LoaderService,
              private route: ActivatedRoute,
              private alert: AlertService,
              private socket: SocketService) {
    // Set the theme to use.
    this._spTheming.setSpThemeName('dashboard-desk');
  }

  createdCompany: Company = null;

  // On init.
  async ngOnInit() {
    this.loader.load();

    this.auth.permissionCheck('company.self.user.manage').then(authorization => {
      this.canManageUser = authorization;
    });

    this.auth.permissionCheck('company.self.manage').then(authorization => {
      this.canManageCompany = authorization;
    });

    this.auth.permissionCheck('user.admin').then(authorization => {
      this.isUserAdmin = authorization;
    });

    this.isCompanyAdmin = await this.auth.permissionCheck('company.admin');

    this.apiPlan.getAll().then((plans: Plan[]) => {
      this.plans = plans;
      this.apiRole.getAll().then((roles: Role[]) => {
        this.roles = roles;

        // When all components are loaded, getting company if existing
        this.getCompanyFromRoute();
      });
    });

    this.companyFormGroup = new UntypedFormGroup({
      name: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      paymentFrequency: new UntypedFormControl('', [
        Validators.required
      ]),
      audienseId: new UntypedFormControl(''),
    });

    this.subscriptionStartDate = new UntypedFormControl(new Date(), [
      Validators.required
    ]);
    this.subscriptionEndDate = new UntypedFormControl(new Date(), [
      Validators.required
    ]);

    this.userDataSource.sort = this.sort;
    this.userDataSource.paginator = this.paginator;

    this.auth.permissionCheck('company.admin').then(authorization => {
      if (!authorization) {
        this.companyFormGroup.get('paymentFrequency').disable();
        this.subscriptionStartDate.disable();
        this.subscriptionEndDate.disable();
        this.backUrl = '/';
      } else {
        this.backUrl = '/admin/companies';
      }
    });
    this.auth.permissionCheck('company.self.manage').then(authorization => {
      if (!authorization) {
        this.companyFormGroup.disable();
        this.userListDisplayedColumns = this.userListDisplayedColumns.filter(c => c !== "actions");
      }
    });

    if (this.isCompanyAdmin) {
      this.apiCompany.getAll({min: true}).then((companies: Company[]) => {
        this.allCompanies = companies;
        this.allCompaniesFiltered = companies;
      });
    }
  }
  showSuccess() {
    this.msgs = [];
    this.msgs.push({severity:'success', summary:'Success Message', detail:'The company has been ' + (this.editMode ? 'updated' : 'created') + ' successfully'});
  }
  modifyMonthlyCredits(value?: number) {
    if (value) {
      this.monthlyCredits += value;
      if (this.monthlyCredits < 0) this.monthlyCredits = 0;
    }

    this.onPlanInfoChange();
  }
  modifyUsers(value?: number) {
    if(value) {
      this.nbrUsers += value;
      if (this.nbrUsers < 0) this.nbrUsers = 0;
    }

    this.onPlanInfoChange();
  }
  modifyExtraCredits(value?: number) {
    if(value) {
      this.extraCredits += value;
      if (this.extraCredits < 0) this.extraCredits = 0;
    }
  }
  onPlanInfoChange() {
    if (this.selectedPlan !== this.customPlan) {
      const previousPlanPerms = this.selectedPlan.permissions;
      const previousPlanIsFreeOrTrial = this.selectedPlan.name == "Free" || this.selectedPlan.name == "Trial";
      this.selectedPlan = this.customPlan;

      const proPlan = this.plans.find(p => p.name == "Pro");
      if (previousPlanIsFreeOrTrial && proPlan) {
        this.customPlan.permissions = proPlan.permissions;
      } else {
        this.customPlan.permissions = previousPlanPerms;
      }
    }

    this.customPlan.credits = this.monthlyCredits;
    this.customPlan.users = this.nbrUsers;
  }
  onPaymentInfoChange(value: string) {
    if (value === 'Trial') {

      this.selectedPlan = this.plans.find(p => p.name == 'Trial') || null;
      if (this.selectedPlan) this.onPlanSelectChange();
    }
  }
  getCompanyFromRoute() {
    this.route.paramMap.subscribe(params => {
      let companyId = params.get('company_id');

      if(companyId) {
        this.loader.load();
        this.apiCompany.getOne(parseInt(companyId)).then(async company => {
          this.loadCompanyInfo(company);
          this.companyDataSource.data = await this.apiCompany.getManagedCompanies(this.createdCompany);
        }, error => {
          if (error.status == 404) {
            this.router.navigate(['page-not-found'])
          } else if (error.status == 403) {
            this.router.navigate(['page-access-denied'])
          }
        }).finally(() => this.loader.loaded());
      } else {
        this.loader.loaded();
      }
    })
  }
  loadCompanyInfo(company: Company) {
    this.companyFormGroup.get('name').setValue(company.name);

    this.companyFormGroup.get('paymentFrequency').setValue(company.subscription.payment_frequency);
    this.subscriptionStartDate.setValue(company.subscription.contract_start_at);

    this.subscriptionEndDate.setValue(company.subscription.contract_expire_at);

    this.monthlyCredits = company.subscription.plan.credits;
    this.extraCredits = company.subscription.credits_extra;
    this.nbrUsers = company.subscription.users;

   this.companyFormGroup.get('audienseId').setValue(company.audiense_id);

    if(company.subscription.plan.custom) {
      this.customPlan = company.subscription.plan;
      this.selectedPlan = this.customPlan;
    } else {
      //this.customPlan = null;
      this.selectedPlan = this.plans.find(p => p.id == company.subscription.plan.id);
    }

    this.selectedImage = company.image === null ? null : this.env.config.api.storageUrl + company.image;

    this.createdCompany = company;
    this.userDataSource.data = this.createdCompany.users;

    this.isNotCreated = false;
    this.editMode = true;
  }
  onPlanSelectChange() {
    if (this.selectedPlan !== null) {
      this.nbrUsers = this.selectedPlan.users;
      this.monthlyCredits = this.selectedPlan.credits;
    } else {
      this.nbrUsers = 0;
      this.monthlyCredits = 0;
    }
  }
  companyCreate() {
    if (!this.validateInfo() || this.createdCompany !== null) return;

    let company = this.buildCompanyFromInfo();

    this.apiCompany.postOne(company).then(company => {
      this.socket.sendMessageType('company-create', {name: company.name});
      this.router.navigateByUrl('admin/companies/update/' + company.id).then();
    })
  }
  companyUpdate() {
    if (!this.validateInfo() || this.createdCompany === null) return;

    let company = this.buildCompanyFromInfo(this.createdCompany.id);

    this.apiCompany.updateOne(company).then(company => {
      // Resetting custom plan by creating a new one and clearing old incorrect plan ID
      if (!company.subscription.plan.custom) this.customPlan = new Plan("Custom plan", 0, 0, []);
      this.loadCompanyInfo(company);
      this.showSuccess();
    })
  }
  companySubscriptionBlock() {
    const newSubEndDate = new Date();
    newSubEndDate.setHours(1);
    if (newSubEndDate.getMonth() == 0) {
       newSubEndDate.setFullYear(newSubEndDate.getFullYear() - 1);
       newSubEndDate.setMonth(11);
    } else {
      newSubEndDate.setMonth(newSubEndDate.getMonth() - 1);
    }
    this.createdCompany.subscription.contract_expire_at = newSubEndDate;
    this.apiCompany.updateOne(this.createdCompany).then(company => {
      this.alert.notify('Success', 'The company subscription has been set to expiration !', 'success');
      this.loadCompanyInfo(company);
    });
  }
  //TODO: Optimize with model instead of variables ?
  buildCompanyFromInfo(companyId?: number) {
    let buildCompany: Company = new Company("");
    if(companyId) buildCompany.id = companyId;

    let startDate: Date = new Date(this.subscriptionStartDate.value);
    startDate.setHours(2); // Adaptation for database | TODO : See for real option with timezone support
    let endDate = new Date(this.subscriptionEndDate.value);
    endDate.setHours(2);
    buildCompany.name = this.companyFormGroup.get('name').value.charAt(0).toUpperCase() + this.companyFormGroup.get('name').value.slice(1);
    buildCompany.audiense_id = this.companyFormGroup.get('audienseId').value;

    let subscription = new Subscription(
      this.monthlyCredits,
      this.nbrUsers,
      this.companyFormGroup.get('paymentFrequency').value,
      startDate,
      endDate
    );
    subscription.credits_extra = this.extraCredits;

    if (this.selectedPlan !== null) {
      subscription.plan = _.cloneDeep(this.selectedPlan); // Avoiding by ref value view artifacts (numbers blinking)

      if (this.selectedPlan.custom) {
        subscription.plan.credits = this.monthlyCredits;
        subscription.plan.users = this.nbrUsers;
      }
    } else {
      // Default permissions are set in the backend if not filled here
      subscription.plan = new Plan("Custom plan", this.monthlyCredits, this.nbrUsers, []);
    }

    buildCompany.subscription = subscription;
    buildCompany.image = this.selectedImage;
    return buildCompany;
  }

  validateInfo(): boolean {
    if (!this.auth.permissionCheck('company.admin')) {
      return this.companyFormGroup.valid
    } else {
      return this.companyFormGroup.valid && this.subscriptionStartDate.valid && this.subscriptionEndDate.valid
    }
  }

  onRoleSelectionChange(user: User, roleId: number) {
    this.switchUserCompanyRole(user, this.createdCompany, roleId);
  }

  switchUserCompanyRole(user: User, company: Company, roleId: number) {
    this.switchRolePending = true;

    const role = this.roles.find(r => r.id == roleId);

    this.apiUser.switchCompanyRole(user, company, role)
      .then(() => {
        user.role = role;
        this.userDataSource.data = this.createdCompany.users;
      })
      .catch(error => {
        if(this.env.config.showErrorModal) this.alert.notify("Error", error.error.message, "error");
      })
      .finally(() => this.switchRolePending = false);
  }

  isRoleDisabled(user: User, role: Role): boolean {
    if (this.createdCompany) {
      if (role.level <= RoleUtils.freemiumLevel && this.auth.session.user.role.level < RoleUtils.superAdminLevel) return true;
      if (role.key !== RoleUtils.trialKey && role.key !== RoleUtils.freemiumKey && user.role.level >= RoleUtils.teamAdminLevel && this.createdCompany.users.filter(u => u.role.level >= RoleUtils.teamAdminLevel).length <= 1) {
        return role.level < RoleUtils.teamAdminLevel || role.level > this.auth.session.user.role.level;
      }

      return role.level > this.auth.session.user.role.level;
    } else {
      return true;
    }
  }

  onImageChange(imageInput: HTMLInputElement) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      this.selectedImage = event.target.result;
    });

    reader.readAsDataURL(file);
  }

  goToUserUpdate(user: User) {
    this.router.navigate(["/admin/users/update/" + user.id], {
      queryParams: {
        'from': 'company'
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.userDataSource.filter = filterValue.trim().toLowerCase();
  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(SpModalCompanyAddUsersComponent,{
      maxHeight:'30rem',maxWidth:'40rem', minWidth:'40rem', data: {
        company: this.createdCompany
      }
    });

    dialogRef.afterClosed().subscribe(company => {
      if(company !== null && company !== undefined) {
        this.createdCompany = company;
        this.userDataSource.data = this.createdCompany.users;
      }
    });
  }

  deleteUser(user: User) {
    this.apiCompany.deleteUser(this.createdCompany, user)
      .then(company => {
        if (company) {
          this.createdCompany = company;
          this.userDataSource.data = this.createdCompany.users;
        }
      })
      .catch(error => {
        if(environment.config.showErrorModal) this.alert.notify("Error", error.error.message, "error");
      });
  }

  openDeleteDialog(user: User): void {
    const dialogRef = this.dialog.open(SpModalUserDeleteComponent,{
      maxHeight:'30rem',maxWidth:'40rem', data: {
        user,
        company: this.createdCompany
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.deleteUser(user);
      }
    });
  }

  permissionsToString(): string {
    return this.selectedPlan && this.selectedPlan.permissions.length > 0 ? ('PERMISSIONS : ' + this.selectedPlan.permissions.map(p => p.name).join(', ')) : 'No permission set'
  }

  sendInvitation(user: User) {
    this.invitationPending.push(user.id);
    this.apiUser.sendInvitation(user).then(() => {
      this.alert.notify('OK!', 'The invitation has been sent successfully', 'success');
      this.invitationSent.push(user.id);
    }).catch(err => {
      this.alert.notify('Error', err.error.message, 'error');
    }).finally(() => {
      this.invitationPending.splice(this.invitationPending.indexOf(user.id), 1);
    })
  }

  protected onSearchCompanyChange(search: string) {
    this.allCompaniesFiltered = this.allCompanies.filter(c => c.name.toLowerCase().includes(search.toLowerCase()));
  }

  addCompanyToAgency(company: Company) {
    this.apiCompany.linkAgency(company, this.createdCompany).then(() => {
      this.companyDataSource.data = this.companyDataSource.data.concat([company]);
    }).catch(err => {
      this.alert.notify('Error', err.error.message, 'error');
    }).finally(() => {
      this.selectedCompany = undefined;
    });
  }

  removeCompanyFromAgency(company: Company) {
    this.apiCompany.unlinkAgency(company, this.createdCompany).then(() => {
      this.companyDataSource.data = this.companyDataSource.data.filter(c => c.id !== company.id);
    }).catch(err => {
      this.alert.notify('Error', err.error.message, 'error');
    }).finally(() => {
      this.selectedCompany = undefined;
    });
  }

  protected companyToString(element?: Company): string {
    return element ? element.name : '';
  }
}
