import {Model} from "./model";
import {NamedItem} from '../pipes/array-name-join.pipe';

export class Universe implements Model, NamedItem {
  id: number;
  name: string;
  description: string;
  limited: boolean;
  country_ref: string;
  country_group: boolean;
  default: boolean;
  segment_default: boolean;
  segment: boolean;
  topics: boolean;
  political: boolean;
  custom_geo?: string;
  default_select: boolean;
  created_at: Date;
  updated_at: Date;

  constructor(name: string, limited: boolean, topics: boolean, political: boolean) {
    this.name = name;
    this.description = 'No description';
    this.limited = limited;
    this.country_ref = 'worldwide';
    this.country_group = true;
    this.default = false;
    this.segment_default = false;
    this.segment = false;
    this.topics = topics;
    this.political = political;
    this.default_select = false;
    this.created_at = new Date();
    this.updated_at = new Date();
  }
}
