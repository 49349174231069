export interface Configuration {
  version: string;
  production: boolean;
  dev: boolean;
  demo: boolean;
  local: boolean;
  noPpImage: string;
  api: {statsUrl: string, baseUrl: string, storageUrl: string};
  socket: {enabled: boolean, host: string, port: number, apiHost: string, apiPort: number, ssl: boolean};
  intercom: {
    enabled: boolean,
    apiBase: string,
    appId: string
  };
  pptx: {
    api: string
  };
  dataManager: {
    domain: string
  };
  chartExportUrl: string;
  apiCheck: boolean;
  apiErrorCheck: boolean;
  permCheck: boolean;
  showErrorModal: boolean;
  crawlErrorBypass: boolean;
  allowCustomTokenUse: boolean;
  enableLogging: boolean;
  showLogMinPriority: number;
  customFlexTypesAsRigid: boolean;
  enableExperimentalFeatures: boolean;
  enableVeryExperimentalFeatures: boolean;
  enableDebugFeatures: boolean;
  showMapEditMenu: boolean;
  switchDataType: string;
  performanceMode: boolean;
  fbAppId: string;
  fbVersion: string;
  adminBypass: boolean;
}

export abstract class DynamicEnvironment {
  public online: boolean;
  public overrideEnv: string;
  public config: Configuration;
  public recaptchaKey: string;
}
